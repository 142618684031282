<template>
  <div class="wrapper">
    <div class="header">
      <div class="left">
        <!-- <div class="todayTime">今天  {{timeArr[0]}}  {{timeArr[2]}}  {{timeArr[1]}}</div> -->
        <div class="todayTime">今天  {{ timeArr }}</div>
        <div class="measure-wrapper">
          <div class="left-temp">
            <img src="@/assets/images/intelligent_device/thermograph.png" alt="">
            <div class="fontSize">{{temperature+'℃'}}</div>
          </div>
          <div class="right-measure" >
            <!-- <div class="box" v-for="(item,index) in headerData" :key="index">
              <div class="centerStyle">
                <div class="textStyle">{{item.text}}</div>
                <img class="imgStyle" :src="item.url" alt="">
              </div>
              <div class="line"></div>
            </div> -->
            <div class="box">
              <div class="centerStyle" >
                <div class="textStyle">{{humidity+'%'}}</div>
                <img class="imgStyle" src="@/assets/images/intelligent_device/humidity.png" alt="">
              </div>
              <div class="line"></div>
              <div class="centerStyle">
                <div class="textStyle">{{airQuality}}</div>
                <img class="imgStyle" src="@/assets/images/intelligent_device/water-quality.png" alt="">
              </div>
              <div class="line"></div>
              <div class="centerStyle" style="margin-right:36px">
                <div class="textStyle">{{temperature+"℃"}}</div>
                <img class="imgStyle" src="@/assets/images/intelligent_device/water-temper.png" alt="">
              </div>
           <!-- <div class="line"></div>
              <div class="centerStyle marginRight">
                <div class="textStyle">{{"6"}}</div>
                <img class="imgStyle" src="@/assets/images/intelligent_device/PH.png" alt="">
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="alarm" @click="goAlarmRecord">
          <div class="colorRed">{{warnCount?warnCount:0}}</div>
          <div>告警</div>
        </div>
        <div class="deviceTotal">
          <div class="colorBlue">{{deviceTotalCount?deviceTotalCount:0}}</div>
          <div>总设备</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="device-box">
        <div class="single-device" v-for="(item,index) in deviceData" :key="index" @click="goToDevice(item.deviceType)">
          <div class="decice-name">{{item.deviceName}}</div>
          <div class="device-content">
            <div class="totalBox" >
              <span class="boxNum">数量：</span>
              <span class="numColor">{{item.deviceCount}}</span>
            </div>
            <img class="device-img" :src="require(`@/assets/images/intelligent_device/${item.deviceType}.png`)" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apis from '@/apis'

export default {
  components: {},
  props: {},
  data() {
    return {
      warnCount: 0,
      deviceTotalCount: 0,
      headerData: [
        {
          text: "65℃",
          url: require('@/assets/images/intelligent_device/humidity.png'),
        },
        {
          text: "优",
          url: require('@/assets/images/intelligent_device/water-quality.png'),
        },
        {
          text: "24℃",
          url: require('@/assets/images/intelligent_device/water-temper.png'),
        },
        {
          text: "6",
          url: require('@/assets/images/intelligent_device/PH.png'),
        },
      ],
      deviceData: [],
      timeArr: [],
      temperature: 0, // 温度
      humidity: 0, // 湿度
      airQuality: '', //空气质量
    };
  },
  watch: {},
  computed: {
    ...mapState(['venueId']),
  },
  methods: {
    getCurrTime() {
      const now = new Date();
      const year = now.getFullYear()
      const month = (now.getMonth() + 1) < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1
      const day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate()
      const hh = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours()
      const mm = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes()
      const ss = now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds()
      const currDay = `${year}-${month}-${day}`
      const currTime = `${hh}:${mm}:${ss}`
      const week = now.getDay()
      const weekArr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
      // const weekCur = weekArr[week]
      // this.timeArr.push(currDay)
      // this.timeArr.push(currTime)
      // this.timeArr.push(weekCur)
      // console.log(timeArr)
      this.timeArr = `${currDay}  ${weekArr[week]}  ${currTime}`;
      return this.timeArr
    },
     toStartTime() {
        this.time = setInterval(() => {
          this.getCurrTime();
        }, 1000);
    },
    getIndexIotDevice() {
      // const newArr = []
      this.$http.get(`${apis.getIndexIotDevice}?venueId=${this.venueId}`).then((res) => {
      if (res.data.code === 0) {
          console.log("res===>智能设备首页", res)
          if (res.data && res.data.data) {
            this.warnCount = res.data.data.warnCount
            this.deviceTotalCount = res.data.data.deviceTotalCount;
            this.deviceData = res.data.data.iotDeviceItems;
          } else {
            this.warnCount = 0
            this.deviceTotalCount = 0
            this.deviceData = []
          }
          if (res.data && res.data.data.weathelData) {
            this.temperature = res.data.data.weathelData.temperature
            this.humidity = res.data.data.weathelData.humidity
            this.airQuality = res.data.data.weathelData.airQuality
          } else {
            this.temperature = 0
            this.humidity = 0
            this.airQuality = ''
          }
        }
      });
    },
    goToDevice(deviceType) {
      switch (deviceType) {
        case "1": this.$router.push("./deviceList/gate");
        break;
        case "2": this.$router.push("./deviceList/pos");
        break;
        case "3": this.$router.push("./deviceList/passengerFlowCamera");
        break;
        case "4": this.$router.push("./deviceList/liveCamera");
        break;
        case "5": this.$router.push("./deviceList/internetDevice/IOTGateway");
        break;
        case "6": this.$router.push("./deviceList/internetDevice/IntelligentSwitchGateway");
        break;
        case "7": this.$router.push("./deviceList/internetDevice/smartLight");
        break;
        case "8": this.$router.push("./deviceList/internetDevice/airDetector");
        break;
        case "9": this.$router.push("./deviceList/internetDevice/weatherDetector");
        break;
        default: this.$router.push("./deviceList/gate");
        break;
      }
    },
    goAlarmRecord() {
      this.$router.push("./devicedeAlarmRecord");
    },
  },
  created() {},
  activated() {
    this.toStartTime();
  },
  mounted() {
    this.getCurrTime()
    this.getIndexIotDevice()

  },
};
</script>
<style lang="scss" scoped>
.wrapper{
  padding: 10px;
  box-sizing: border-box;
    .header{
      display: flex;
      justify-content: space-between;
      .left{
        flex: 1;
        min-width: 768px;
        height: 143px;
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        .todayTime{
          margin-top: 12px;
          margin-left: 16px;
          font-size: 16px;
          font-weight: bold;
          color: #000000;
        }
        .measure-wrapper{
          display: flex;
          justify-content: space-between;
          .left-temp{
            margin-top: 13px;
            min-width: 254px;
            display: flex;
            justify-content: center;
            align-items: center;
             img{
              width: 42px;
              height: 42px;
              }
            .fontSize{
              margin-left: 5px;
              font-size: 40px;
              font-weight: bold;
              color: #439BFF;
            }
          }
          .right-measure{
            display: flex;
            justify-content: center;
            .box{
               display: flex;
               justify-content: center;
               align-items: center;
                .centerStyle{
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                }
              .textStyle{
                font-size: 24px;
                color: #439BFF;
              }
              .imgStyle{
                width: 20px;
                height: 20px;
              }
              .line{
                margin:0 40px;
                width: 2px;
                height: 40px;
                background: #439BFF;
                border-radius: 1px;
              }
              .marginRight{
                margin-right: 40px;
              }
            }
            // .box:last-child .line{
            //   width: 0;
            //   height: 0;
            // }
          }
        }
      }
      .right{
        display: flex;
        justify-content: space-between;
        .alarm,.deviceTotal{
          min-width: 168px;
          height: 143px;
          background: #FFFFFF;
          border-radius: 4px;
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .colorRed,.colorBlue{
          font-size: 30px;
          font-weight: bold;
        }
        .colorRed{
          color: #E02020;
        }
        .colorBlue{
          color: #439BFF;
        }
      }
    }
    .content{
      margin-top: 10px;
      padding:20px;
      min-width: 1130px;
      height: 537px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #E4E9F1;
      box-sizing: border-box;
      .device-box{
        display: flex;
        // justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        .single-device{
          padding-top: 30px;
          padding-left: 30px;
          box-sizing: border-box;
          min-width: 250px;
          // height: 200px;
          background: #FFFFFF;
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          margin-bottom: 30px;
          margin-right: 50px;
          .device-content{
            display: flex;
            align-items: flex-end;
            justify-content: space-around;
            .totalBox{
              margin-bottom: 28px;
              .boxNum{
                font-size: 12px;
                color: #999999;
              }
              .numColor{
                font-size: 24px;
                color: #439BFF;
              }
            }
            .device-img{
              display: inline-block;
              width: 146px;
              height: 133px;
            }

          }
        }
        .single-device:nth-child(5n){
          // margin-right: 0px;
        }
      }
      .device-box:after{
        content: "";
        flex: auto;
        margin-right: 0;
      }
    }
}

</style>
